body{
  background-color: #F8FBFA;
  overflow-x: hidden;
}

.App {
  text-align: center;
  background-color: #F8FBFA;
  overflow-x: hidden;
}


