/* styles.css */
.title-slide-up {
  opacity: 0 !important;
  transform: translateY(-30px) !important;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out !important;
}

.title-slide-up.show {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.card-slide-up {
  opacity: 0 !important;
  transform: translateY(30px) !important;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out !important;
}

.card-slide-up.show {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

/* Add RTL support */
[dir="rtl"] .title-slide-up {
  transform: translateY(-30px) !important; /* Adjust this if needed */
}

[dir="rtl"] .title-slide-up.show {
  transform: translateY(0) !important;
}

[dir="rtl"] .card-slide-up {
  transform: translateY(30px) !important; /* Adjust this if needed */
}

[dir="rtl"] .card-slide-up.show {
  transform: translateY(0) !important;
}
